import { Fragment } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

// component for the confirmation page for the second part of the frontend
const Confirm = ({ setStep, formData, setFormData, handleSubmit }) => {
  // get all the filename the user attached
  /**
   *
   * @param {[File Object]} fileList - list of files the user attached in the form
   * @returns - a list of file names
   */
  const getFileArray = (fileList) => {
    let retval = [];
    for (const item in fileList) {
      retval.push(fileList[item]["name"]);
    }
    return retval;
  };

  // get first file name if there is any
  /**
   *
   * @param {[File Object]} fileList - list of files the user attached in the form
   * @returns the name of the first file if it exists
   */
  const getFirstFileName = (fileList) => {
    if (fileList) {
      if (fileList.length >= 1) {
        return getFileArray(fileList)[0];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  // get second file name if there is any
  /**
   *
   * @param {[File Object]} fileList - list of files the user attached in the form
   * @returns the name of the second file if it exists
   */
  const getSecondFileName = (fileList) => {
    if (fileList) {
      if (fileList.length >= 2) {
        return getFileArray(fileList)[1];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  // get third file name if there is any
  /**
   *
   * @param {[File Object]} fileList - list of files the user attached in the form
   * @returns the name of the third file if it exists
   */
  const getThirdFileName = (fileList) => {
    if (fileList) {
      if (fileList.length >= 3) {
        return getFileArray(fileList)[2];
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  return (
    <Fragment>
      <br />
      <h1 className="mb-4">Confirm</h1>
      <div class="row">
        <div class="column">
          <label className="confirmLabel">Vendor</label>
          <input
            disabled={true}
            placeholder={formData.vendor}
            className="wpcf7-form-control"
          />
        </div>
        <div class="column">
          <label className="confirmLabel">Suite</label>
          <br />
          <input
            disabled={true}
            placeholder={formData.suite}
            className="wpcf7-form-control"
          />
        </div>
        <div class="column">
          <label className="confirmLabel">Product</label>
          <br />
          <input
            disabled={true}
            placeholder={formData.product}
            className="wpcf7-form-control"
          />
        </div>
      </div>
      <br />
      <div class="row">
        <div class="column">
          <label className="confirmLabel">Issue Type</label>
          <input
            disabled={true}
            placeholder={formData.issueType}
            className="wpcf7-form-control"
          />
        </div>
        <div class="column">
          <label className="confirmLabel">Version</label>
          <input
            disabled={true}
            placeholder={formData.version}
            className="wpcf7-form-control"
          />
        </div>
      </div>
      <br />
      <label className="confirmLabel">Title</label>
      <input
        disabled={true}
        placeholder={formData.title}
        className="wpcf7-form-control"
      />
      <br />
      <br />
      <label className="confirmLabel">Description</label>
      <ReactQuill
        readOnly
        theme="snow"
        value={formData.description}
        className="richtextbox"
      />
      <br />
      {getFirstFileName(formData.files) != "" && (
        <>
          <label className="confirmLabel">File 1</label>
          <input
            disabled={true}
            placeholder={getFirstFileName(formData.files)}
            className="wpcf7-form-control"
          />
        </>
      )}
      <br />
      {getSecondFileName(formData.files) != "" && (
        <>
          <label className="confirmLabel">File 2</label>
          <input
            disabled={true}
            placeholder={getSecondFileName(formData.files)}
            className="wpcf7-form-control"
          />
        </>
      )}
      <br />
      {getThirdFileName(formData.files) != "" && (
        <>
          <label className="confirmLabel">File 3</label>
          <input
            disabled={true}
            placeholder={getThirdFileName(formData.files)}
            className="wpcf7-form-control"
          />
        </>
      )}
      <br />
      <div className="button-row">
        <button variant="secondary" onClick={() => setStep((step) => step - 1)}>
          <b>{"<"} Go Back</b>
        </button>
        <button
          type="submit"
          onClick={() => {
            setStep((step) => step + 1);
            handleSubmit();
          }}
          style={{ marginLeft: "50px" }}
        >
          <b>Submit</b>
        </button>
      </div>
    </Fragment>
  );
};

export default Confirm;
