import React, { Fragment } from "react";
import { useState, useEffect } from "react";
import "./bootstrap.css";
import Spinner from "../util/spinner.jsx";
import frontendConfig from "../frontendConfig.json";

// a component for the product selection page
const ProductSelection = ({ setStep, formData, setFormData }) => {
  // for each user, we have a unique link with a slug, which is a unique id associated with them
  let slug = new URLSearchParams(window.location.search);
  slug = slug.get("id");

  const [fetched, setFetched] = useState(false);
  const [valid, setValid] = useState(true);

  const [personalInformation, setPersonalInformation] = useState(null);
  useEffect(() => {
    if (!fetched) {
      const fetchData = async () => {
        let tempData = await (
          await fetch(`${frontendConfig.personalInformation}?id=${slug}`)
        ).json();

        setFetched(true);

        if (tempData.error) {
          setValid(false);
        } else {
          setValid(true);

          setPersonalInformation(tempData);
          tempData["vendor"] = formData.vendor;
          tempData["suite"] = formData.suite;
          tempData["product"] = formData.product;
          tempData["issueType"] = formData.issueType;
          tempData["version"] = formData.version;
          tempData["description"] = formData.description;
          tempData["files"] = formData.files;
          tempData["multiMediaDescription"] = formData.multiMediaDescription;
          setFormData(tempData);
        }
      };

      fetchData();
    }
  }, [fetched, slug]);

  // onclick function for the next button
  const onClick = () => {
    if (
      formData.vendor === "" ||
      formData.suite === "" ||
      formData.product === "" ||
      formData.issueType === "" ||
      formData.version === "" ||
      !formData.vendor ||
      !formData.suite ||
      !formData.product ||
      !formData.issueType ||
      !formData.version
    ) {
      alert("Please make selection to all the dropdown boxes");
    } else {
      setStep((step) => step + 1);
    }
  };

  const [vendorSelected, setVendorSelected] = useState(false);
  const [suiteSelected, setSuiteSelected] = useState(false);
  const [productSelected, setProductSelected] = useState(false);
  const [versionSelected, setVersionSelected] = useState(false);

  const [vendor, setVendor] = useState(formData.vendor);
  const [suite, setSuite] = useState(formData.suite);
  const [product, setProduct] = useState(formData.product);
  const [version, setVersion] = useState(formData.version);

  const [vendorType, setVendorType] = useState([]);
  const [suiteType, setSuiteType] = useState([]);
  const [productType, setProductType] = useState([]);
  const [versionType, setVersionType] = useState([]);

  const [data, setData] = useState(null);

  const [vendorOptions, setVendorOptions] = useState(null);
  const [suiteOptions, setSuiteOptions] = useState(null);
  const [productOptions, setProductOptions] = useState(null);
  const [versionOptions, setVersionOptions] = useState(null);

  /**
   *
   * @param {JSON} DATA - a json object that stores all the vendor, suite, product, version information fetched from the backend
   * @param {string} VENDOR - vendor
   * @param {string} SUITE - suite
   * @param {string} PRODUCT - product
   * @param {string} VERSION - version
   * change the items in the dropdown lists for product selection
   */
  const changeLists = function (DATA, VENDOR, SUITE, PRODUCT, VERSION) {
    let vendList = Object.keys(DATA);
    let vend = VENDOR ? VENDOR : vendList[0];

    let suiteList = [];
    let sui = "";
    let prodList = [];
    let prod = "";
    let versionList = [];
    let ver = "";

    if (vend !== "") {
      suiteList = Object.keys(DATA[vend]);
      sui = SUITE ? SUITE : suiteList[0];
    }
    if (vend !== "" && sui !== "") {
      prodList = Object.keys(DATA[vend][sui]);
      prod = PRODUCT ? PRODUCT : prodList[0];
    }
    if (vend !== "" && sui !== "" && prod !== "") {
      versionList = Object.values(DATA[vend][sui][prod]);
      ver = VERSION ? VERSION : versionList[0];
    }

    setVendor(vend);
    setSuite(sui);
    setProduct(prod);
    setVersion(ver);

    setVendorType(vendList);
    setSuiteType(suiteList);
    setProductType(prodList);
    setVersionType(versionList);
  };

  useEffect(() => {
    const fetchData = async () => {
      let tempData = await (
        await fetch(frontendConfig.vendorSuiteProductVersion)
      ).json();

      setData(tempData);
      changeLists(
        tempData,
        formData.vendor,
        formData.suite,
        formData.product,
        formData.version
      );
    };
    fetchData();
  }, []);

  const [productInfo, setProductInfo] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      let tempData = await (await fetch(frontendConfig.productInfo)).json();

      setProductInfo(tempData);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setVendorOptions(
      vendorType.map((el) => (
        <option selected={formData.vendor === el} key={el} className="none">
          {el}
        </option>
      ))
    );
    setSuiteOptions(
      suiteType.map((el) => (
        <option selected={formData.suite === el} key={el} className="none">
          {el}
        </option>
      ))
    );
    setProductOptions(
      productType.map((el) => (
        <option selected={formData.product === el} key={el} className="none">
          {el}
        </option>
      ))
    );
    setVersionOptions(
      versionType.map((el) => (
        <option selected={formData.version === el} key={el} className="none">
          {el}
        </option>
      ))
    );
  }, [
    vendorType,
    suiteType,
    productType,
    versionType,
    vendor,
    suite,
    product,
    version,
  ]);

  /**
   *
   * @param {EVENT} event - the event for vendor dropdown list
   */
  const onChangeVendor = (event) => {
    changeLists(data, event.target.value);
    setVendorSelected(true);
    setFormData({
      ...formData,
      vendor: event.target.value,
      suite: "",
      product: "",
      issueType: "",
      version: "",
    });
  };

  /**
   *
   * @param {EVENT} event - the event for suite dropdown list
   */
  const onChangeSuite = (event) => {
    changeLists(data, formData.vendor, event.target.value);
    setSuiteSelected(true);
    setFormData({
      ...formData,
      suite: event.target.value,
      product: "",
      issueType: "",
      version: "",
    });
  };

  /**
   *
   * @param {EVENT} event - the event for product dropdown list
   */
  const onChangeProduct = (event) => {
    changeLists(data, formData.vendor, formData.suite, event.target.value);
    setProductSelected(true);
    setFormData({
      ...formData,
      product: event.target.value,
      issueType: "",
      version: "",
    });
  };

  /**
   *
   * @param {EVENT} event - the event for version dropdown list
   */
  const onChangeVersion = (event) => {
    changeLists(
      data,
      formData.vendor,
      formData.suite,
      formData.product,
      event.target.value
    );
    setVersionSelected(true);
    setFormData({
      ...formData,
      version: event.target.value,
    });
  };

  return (
    <>
      <>
        <>
          {personalInformation !== null && valid && (
            <Fragment>
              <div style={{ overflow: "hidden" }}>
                {data && (
                  <Fragment>
                    {" "}
                    <div class="row">
                      <div class="column">
                        <label
                          className="form__label"
                          htmlFor="vendor"
                          style={{ color: "grey" }}
                        >
                          Vendor{" "}
                        </label>
                        <select
                          required
                          onChange={onChangeVendor}
                          name="vendor"
                          className="dropdown wpcf7-form-control"
                          value={formData.vendor}
                          style={{ border: "2px solid #e4e6e5" }}
                        >
                          <option value="" disabled selected className="none">
                            —Please choose an option—
                          </option>
                          {vendorOptions}
                        </select>
                      </div>
                      {formData.vendor && formData.vendor !== "" && (
                        <div class="column">
                          <label
                            className="form__label"
                            htmlFor="suite"
                            style={{ color: "grey" }}
                          >
                            Suite{" "}
                          </label>
                          <select
                            required
                            onChange={onChangeSuite}
                            name="suite"
                            className="dropdown wpcf7-form-control"
                            value={formData.suite}
                            style={{ border: "2px solid #e4e6e5" }}
                          >
                            <option value="" disabled selected>
                              —Please choose an option—
                            </option>
                            {suiteOptions}
                          </select>
                        </div>
                      )}
                      {formData.suite && formData.suite !== "" && (
                        <div class="column">
                          <label
                            className="form__label"
                            htmlFor="product"
                            style={{ color: "grey" }}
                          >
                            Product{" "}
                          </label>
                          <select
                            required
                            onChange={onChangeProduct}
                            name="product"
                            className="dropdown wpcf7-form-control"
                            value={formData.product}
                            style={{ border: "2px solid #e4e6e5" }}
                          >
                            <option className="none" value="" disabled selected>
                              —Please choose an option—
                            </option>
                            {productOptions}
                          </select>
                        </div>
                      )}
                    </div>
                    <br />
                    <div class="row">
                      {formData.product &&
                        formData.product !== "" &&
                        productInfo && (
                          <>
                            <div class="column">
                              <label
                                className="form__label"
                                htmlFor="issueType"
                                style={{ color: "grey" }}
                              >
                                {"Issue Type"}{" "}
                              </label>
                              <select
                                required
                                name="issueType"
                                className="dropdown wpcf7-form-control"
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    issueType: e.target.value,
                                    version: "",
                                  });
                                }}
                                value={formData.issueType}
                                style={{ border: "2px solid #e4e6e5" }}
                              >
                                <option
                                  value=""
                                  disabled
                                  selected
                                  className="none"
                                >
                                  —Please choose an option—
                                </option>
                                {productInfo[0]["issueType"].map(
                                  (singleOption) => {
                                    return (
                                      <option
                                        className="none"
                                        key={singleOption}
                                        value={singleOption}
                                      >
                                        {singleOption}
                                      </option>
                                    );
                                  }
                                )}
                              </select>
                            </div>
                          </>
                        )}
                      <div class="column">
                        {formData.issueType && formData.issueType !== "" && (
                          <>
                            <label
                              className="form__label"
                              htmlFor="version"
                              style={{ color: "grey" }}
                            >
                              {"Version"}{" "}
                            </label>
                            <select
                              required
                              onChange={onChangeVersion}
                              name="version"
                              className="dropdown wpcf7-form-control"
                              value={formData.version}
                              style={{ border: "2px solid #e4e6e5" }}
                            >
                              <option
                                value=""
                                disabled
                                selected
                                className="none"
                              >
                                —Please choose an option—
                              </option>
                              {versionOptions}
                            </select>
                          </>
                        )}
                      </div>
                    </div>
                  </Fragment>
                )}
                {!productInfo && (
                  <div>
                    <Spinner show={!productInfo} />
                  </div>
                )}
              </div>
              <div className="button-row">
                <button
                  variant="secondary"
                  type="button"
                  onClick={onClick}
                  style={{ marginTop: "30px" }}
                >
                  <b>Next {">"}</b>
                </button>
              </div>
            </Fragment>
          )}
        </>
        <>{!valid && <p>The URL you entered is invalid.</p>}</>
      </>
    </>
  );
};

export default ProductSelection;
