import Spinner from "react-bootstrap/Spinner";
function LoadingSpinner({ show, className, offsetX, offsetY }) {
  if (show)
    return (
      <Spinner
        animation="border"
        role="status"
        className={className ? className : ""}
        style={{
          position: "absolute",
          left: `calc(50% - 2rem - ${offsetX ? offsetX : 0}px)`,
          top: `calc(50% - 2rem - ${offsetY ? offsetY : 0}px)`,
          width: "4rem",
          height: "4em",
          border: "0.8rem solid currentColor",
          borderRightColor: "transparent",
          opacity: "0.75",
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    );
  else return null;
}
export default LoadingSpinner;
