import React, { useState, Fragment } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./bootstrap.css";

// a component for the issue description page
const IssueDescriptionNew = ({ setStep, formData, setFormData }) => {
  // set the max file size the user can attach
  // can modify easily
  const MAX_FILE_SIZE = 1e10; // 10GB

  // set the max number of files the user can attach
  // if modified, should change the confirm page accordingly since it only renders the confirmation of three files now
  const MAX_NUM_FILES = 3;

  const [files, setFiles] = useState(formData.files);

  /**
   * handles the onClick for the next button
   * @returns true if the user can proceed to the next page, false otherwise
   */
  const onClick = () => {
    if (
      formData.title === "" ||
      formData.description === "" ||
      !formData.title ||
      !formData.description
    ) {
      alert("Please enter all the required fields");
      return false;
    } else {
      return true;
    }
  };

  /**
   *
   * @param {Event} event - the Event for the choose file button
   * @returns nothing
   */
  const handleChange = (event) => {
    const selectedFiles = event.target.files;
    if (selectedFiles.length > MAX_NUM_FILES) {
      alert(`You can only select a maximum of ${MAX_NUM_FILES} files.`);
      return;
    }

    const newFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      if (selectedFiles[i].size > MAX_FILE_SIZE) {
        alert(
          `File #${i + 1} is too large. Please select a file smaller than ${
            MAX_FILE_SIZE / 1000000
          } MB.`
        );
        return;
      }
      newFiles.push(selectedFiles[i]);
    }
    setFiles(newFiles);
    setFormData({
      ...formData,
      files: newFiles,
    });
  };

  return (
    <Fragment>
      <div>
        <label
          htmlFor="title"
          className="title"
          style={{ color: "grey", marginTop: "12px" }}
        >
          {"Brief Description of Question"}
        </label>
        <input
          id="required"
          required
          className="title wpcf7-form-control"
          type="text"
          maxLength={120}
          name="title"
          onChange={(e) => {
            setFormData({
              ...formData,
              title: e.target.value,
            });
          }}
          value={formData.title}
        />
      </div>
      <div>
        <label
          htmlFor="description"
          className="description"
          style={{ color: "grey", marginTop: "12px" }}
        >
          Details
          <p className="tip" style={{ color: "grey", margin: 0 }}>
            <span class="highlight">
              <b>TIP</b>
            </span>
            &nbsp;&nbsp; You can upload or paste screenshots
          </p>
        </label>
      </div>
      <div style={{ flex: 1, width: "100%" }}>
        <ReactQuill
          style={{ height: "100%", width: "100%" }}
          className="reactquill"
          theme="snow"
          value={formData.description}
          onChange={(e) => {
            setFormData({
              ...formData,
              description: e,
            });
          }}
        />
      </div>
      <br />
      <br />
      <div
        style={{
          width: "100%",
          marginBottom: "-30vh",
        }}
      >
        <input
          type="file"
          class="file"
          multiple
          onChange={handleChange}
          title=" "
        />
        {files && files.length !== 0 && <p>Selected files:</p>}
        {files && (
          <ul>
            {files.map((file) => (
              <li key={file.name}>{file.name}</li>
            ))}
          </ul>
        )}
        <button
          style={{ marginLeft: "50px" }}
          variant="secondary"
          type="button"
          className="right"
          onClick={() => {
            if (onClick()) {
              setStep((step) => step + 1);
            }
          }}
        >
          <b>Next {">"}</b>
        </button>
        <button
          variant="secondary"
          onClick={() => {
            setStep((step) => step - 1);
          }}
          className="right"
        >
          <b>{"<"} Previous</b>
        </button>
      </div>
    </Fragment>
  );
};

export default IssueDescriptionNew;
