import "./App.css";
import Header from "./components/Header";
import "./components/bootstrap.css";
import React, { useState, useEffect } from "react";
import PersonalInformation from "./components/PersonalInformation";
import ProductSelection from "./components/ProductSelection";
import IssueDescriptionNew from "./components/IssueDescriptionNew";
import Confirm from "./components/Confirm";
import { AppProvider } from "./state";
import axios from "axios";
import frontendConfig from "./frontendConfig.json";

/**
 *
 * @param {int} step - current page on the second part of the frontend
 * @param {useState} setStep - set step
 * @param {map} formData - a map to store the form data
 * @param {useState} setFormData - set formData
 * @param {Function} handleSubmit - handle submit when the user presses the submit button on the second part of the frontend
 * @returns - the corresponding component to render for the second part of the frontend
 */
const formSwitch = (step, setStep, formData, setFormData, handleSubmit) => {
  switch (step) {
    case 0:
      return (
        <ProductSelection
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 1:
      return (
        <IssueDescriptionNew
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
        />
      );
    case 2:
      return (
        <Confirm
          setStep={setStep}
          formData={formData}
          setFormData={setFormData}
          handleSubmit={handleSubmit}
        />
      );
    case 3:
      return (
        <p>
          <br />
          <div>Thank you for your submission!</div>
        </p>
      );
    default:
      return <div>error</div>;
  }
};

/**
 *
 * @param {int} formStepFirstHalf - current page on the first part of the frontend
 * @param {useState} setFormStepFirstHalf - set formStepFirstHalf
 * @param {map} personalInformation - a map that stores the form data the user enters on the first part of the frontend
 * @param {useState} setPersonalInformation - set personalInformation
 * @param {Function} handleSubmit - handle submit when the user presses submit button on the first part of the frontend
 * @returns - the corresponding component to render for the first part of the frontend
 */
const formSwitchFirstPart = (
  formStepFirstHalf,
  setFormStepFirstHalf,
  personalInformation,
  setPersonalInformation,
  handleSubmit
) => {
  switch (formStepFirstHalf) {
    case 0:
      return (
        <PersonalInformation
          personalInformation={personalInformation}
          setPersonalInformation={setPersonalInformation}
          setStep={setFormStepFirstHalf}
          handleSubmit={handleSubmit}
        />
      );
    case 1:
      return (
        <>
          <br />
          <p>
            Your personal information has been submitted successfully, please
            check your email for the link to submit your question.
          </p>
        </>
      );
  }
};

// main application page
function App() {
  // handle submit for the first part of the form and post the personal information to
  /**
   *
   * @param {EVENT} e - the event for the submit button for the first part of the frontend
   */
  const handleSubmit = async (e) => {
    axios
      .post(frontendConfig.personalInformation, personalInformation)
      .then(() =>
        console.log("successfully posted personal information to backend")
      )
      .catch((err) => {
        console.error(err);
      });
    alert("You have successfully submitted this form");
  };

  /**
   *
   * @param {EVENT} e - the event for the submit button for the second part of the frontend
   */
  const handleSubmitForEntireForm = async (e) => {
    let encodedFormData = new FormData();
    for (const [key, value] of Object.entries(formData)) {
      if (key == "files" && value) {
        for (const [i, file] of value.entries()) {
          encodedFormData.append(`files`, file, file.name);
        }
      } else if (key == "multiMediaDescription" && value) {
        for (const [i, item] of value.entries()) {
          if (typeof item === "string") {
            encodedFormData.append(key, item);
          } else {
            encodedFormData.append(`files`, item, item.name);
            encodedFormData.append(key, item.name);
          }
        }
      } else {
        encodedFormData.append(key, value);
      }
    }

    axios
      .post(frontendConfig.fullFormData, encodedFormData)
      .then(() =>
        console.log("successfully posted the entire form data to backend")
      )
      .catch((err) => {
        console.error(err);
      });
    alert("You have successfully submitted this form");
  };

  // const { slug } = useParams();
  let slug = new URLSearchParams(window.location.search);
  slug = slug.get("id");
  const [formStep, setFormStep] = useState(0);
  const [formStepFirstHalf, setFormStepFirstHalf] = useState(0);

  // fetch the product infos from backend
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      let tempData = await (await fetch(frontendConfig.productInfo)).json();

      setData(tempData);
    };
    fetchData();
  }, []);

  const [formData, setFormData] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    customerEmail: "",
    phone: "",
    preferredContactMethod: "phone",
    vendor: "",
    suite: "",
    product: "",
    issueType: "",
    version: "",
    title: "",
    description: "",
    files: null,
  });

  const [personalInformation, setPersonalInformation] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    customerEmail: "",
    phone: "",
    preferredContactMethod: "phone",
    title: "",
    accessTime: 0,
  });

  let FormSwitchDiv = formSwitch(
    formStep,
    setFormStep,
    formData,
    setFormData,
    handleSubmitForEntireForm
  );

  let FormFirstHalf = formSwitchFirstPart(
    formStepFirstHalf,
    setFormStepFirstHalf,
    personalInformation,
    setPersonalInformation,
    handleSubmit
  );

  return (
    <AppProvider>
      <div>
        <div
          className="form"
          style={{
            width: "100%",
            padding: "0",
            marginLeft: "0%",
            height: "100vh",
          }}
        >
          <div className="form-body">
            {data !== null && (
              <form
                onSubmit={handleSubmit}
                action="/support/personalInformation"
                method="POST"
                className="form"
                encType="multipart/form-data"
                style={{
                  width: "100%",
                  margin: "0",
                  padding: "0",
                  height: "70vh",
                  // height: "70vh",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                }}
                id="form"
              >
                {!slug && FormFirstHalf}
                {slug && FormSwitchDiv}
              </form>
            )}
          </div>
        </div>
      </div>
    </AppProvider>
  );
}

export default App;
